export function handleSiteModal(element, status) {
  if (status === "open") {
    if (element instanceof HTMLDialogElement) {
      element.showModal();
    } else {
      element.hidden = false;
    }
  } else {
    if (element instanceof HTMLDialogElement) {
      element.close();
    } else {
      element.hidden = true;
    }
  }
}

